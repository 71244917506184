module.exports = {
  baseAPIDev: 'http://localhost:8000',
  baseAPI: 'https://api.waeasyapi.com',

  signinURL: 'https://dashboard.waeasyapi.com/signin',
  signupURL: 'https://dashboard.waeasyapi.com/signup',

  facebookURL: 'https://www.facebook.com/waeasyapi',
  twitterURL: 'https://www.twitter.com/waeasyapi',
  telegramURL: 'https://t.me/waeasyapi'
};