import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: false,

  state: {
    mobile: '',
    callingCode: '91',

    cloading: false,
    countries: [],

    pfetching: false,
    pricing: false,

    rfetching: false,
    resources: false,

    isMobile: !window || !window.innerWidth || window.innerWidth < 855
  }
});

export default store;