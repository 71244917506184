<template>
  <v-container>
    <div style="height: 30px;"></div>

    <v-row>
      <v-col class="text-center">
        <h1 :class="[isMobile ? '' : 'ccheadline', 'mb-2']">Terms and Conditions</h1>
        <p :class="['mb-2', isMobile ? '' : 'f125']">By using WA Easy API, you agree to following terms</p>
        <small>Updated 15 June, 2022</small>
      </v-col>
    </v-row>

    <v-row class="mt-10 mb-10">
      <v-col><v-divider></v-divider></v-col>
    </v-row>

    <v-row>
      <v-col>
        <p>WA Easy API is engaged in offering the service of Official WhatsApp Business APIs for entities aiming to register for Official WhatsApp Business APIs hereinafter referred as “Services”. The Services offered by WA Easy API also include various products and services to help you sending messages to your contacts via APIs or using the WA Easy API platform. (collectively called as ‘Services’)</p>
        <p>WA Easy API is a Proprietorship incorporated firm under the Companies Act, 2013 (“the Company”).</p>
        <p>The domain name "waeasyapi.com" (hereinafter referred as “website”) is owned and operated by the Company.</p>
        <p>By signing up for a WA Easy API Account, you are agreeing to be bound by the following terms and conditions (the “T&Cs”).</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!isMobile" cols="3">
        <v-list class="pclist ml-0" style="position: sticky; top: 0;">
          <v-list-item v-for="(item, index) in pitems" :key="index" class="link" @click="goto(index)">
            <v-list-item-content>
              <p class="mb-0"><b>{{ item }}</b></p>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon :color="isDT ? 'white' : 'black'" v-if="index == activeIndex">mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-col>
      
      <v-col :class="[isMobile ? '' : 'pt-8 pl-10']">
        <div id="general">
          <h3 class="mb-5 link">{{ pitems[0] }}</h3>

          <ol>
            <li>The T&Cs concern your access and usage of the WA Easy API.com as well as any other related media form, media channel, mobile website or mobile application (collectively, the “Site”), and availing the Services of the Company. It is hereby clarified that this T&Cs is applicable to customers of WA Easy API (‘businesses’), clients of such customers (‘clients of businesses’), channel partners, and any other related third party. (collectively called as ‘you’).</li>
            <li>The Terms of Service shall be governed by and interpreted in accordance with the laws of India applicable therein, without regard to principles of conflicts of laws.</li>
            <li>You agree that by accessing the Site and availing the Services, it has read, understood, and agrees to be bound by all of these T&Cs. If you do not agree with all of these T&Cs, then it is expressly prohibited from using the Site and you must discontinue use immediately.</li>
            <li>The T&Cs will be provided on the website at all times and the Company reserves the right, to make changes or modifications to these T&Cs. The Company shall alert you about any changes by only updating the “Last updated” date of these T&Cs, and you waive any right to receive specific notice of such change.</li>
            <li>All contents of, and materials located on, this App or website, including but not limited to the text, images and other information herein and their arrangement, are protected by copyright, trademark and other proprietary rights of WA Easy API (also includes its group/associate companies/brand). This means that you shall not without the express written consent of WA Easy API, distribute the text or graphics to others; "mirror" or include this information on their own server or documents; or modify or re-use the text or graphics on this system.</li>
            <li>All registration information submitted by the customers/channel partners/any person on signup up on WA Easy API’s portal is considered as true, accurate, current, and complete. WA Easy API is not liable to anyone (in any manner whatsoever) in case these information are later found out to be false.</li>
            <li>You will maintain the accuracy of such information and promptly update such registration information as necessary.</li>
            <li>You have the legal capacity and agree to comply with these T&Cs.</li>
            <li>You are not under the age of 18</li>
            <li>You are not a minor in the jurisdiction in which you reside, (or if a minor, you have received parental permission to use the Site).</li>
            <li>You warrant that will not access the Site through automated or non-human means, whether through a bot, script, or otherwise.</li>
            <li>You warrant that will not use the Site for any illegal or unauthorized purpose.</li>
            <li>You warrant that it’s use of the Site will not violate any applicable law or regulation.</li>
            <li>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).</li>
            <li>If any provision, or portion of the provision, in these Terms of Service is, for any reason, held to be invalid, illegal or unenforceable in any respect, then such invalidity, illegality or unenforceability will not affect any other provision (or the unaffected portion of the provision) of the Terms of Service, and the Terms of Service will be construed as if such invalid, illegal or unenforceable provision, or portion of the provision, had never been contained within the Terms of Service.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="account">
          <h3 class="mb-5">{{ pitems[1] }}</h3>
          <ol>
            <li>To access and use the Services, you must register for a WA Easy API account (“Account”) by providing your full legal name, current address, phone number, a valid email address, and any other information as required. WA Easy API may reject your application for an Account, or cancel an existing Account, for any reason, in our sole discretion.</li>
            <li>You agree to keep your login details provided by the Company including one time passwords confidential and you will solely be responsible for use of your account. WA Easy API cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account.</li>
            <li>You confirm that you are receiving any Services provided by WA Easy API for the purposes of carrying on a business/commercial activity and not for any activities/goods which are prohibited by applicable laws.</li>
            <li>You acknowledge that WA Easy API will use the email address/phone number provided by you while opening an Account or as updated by you from time to time, as the primary method for communication with you. You must monitor the primary Account email address you provide to WA Easy API and your primary Account email address must be capable of both sending and receiving messages. Your email communications with WA Easy API can only be authenticated if they come from your primary Account email address.</li>
            <li>WA Easy API is not a marketplace/intermediary. It is expressly agreed between the parties that the contract for sale of any product/services shall be a strictly bipartite contract between you and the seller of such product/service.</li>
            <li>You are solely responsible for all activity and content such as products, photos, images, videos, graphics, written content, audio files, code, information, or data uploaded, collected, generated, stored, displayed, distributed, transmitted or exhibited on or in connection with your Account (“Materials”).</li>
            <li>A breach or violation of any term in the Terms of Service, as determined in the sole discretion of WA Easy API may result in an immediate termination of your account. Further, the Company may report such violations to external authorities including law enforcement agencies.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="rights">
          <h3 class="mb-5">{{ pitems[2] }}</h3>
          <ol>
            <li>We reserve the right to modify or terminate the Services for any reason, without notice at any time. Not all Services and features are available in every jurisdiction and we are under no obligation to make any Services or features available in any jurisdiction.</li>
            <li>We reserve the right to refuse service to anyone including clients of businesses for any reason at any time.</li>
            <li>We may, but have no obligation to, remove Materials and suspend or terminate Accounts if we determine in our sole discretion that the goods or services offered via a Store, or the Materials uploaded or posted to a Store, violate T&C.</li>
            <li>Verbal or written abuse of any kind (including threats of abuse or retribution) of any WA Easy API customer, WA Easy API employee, member, or officer will result in immediate Account termination.</li>
            <li>WA Easy API does not pre-screen Materials/goods and it is in our sole discretion to refuse or remove any Materials/goods from the Service, including your Store.</li>
            <li>We reserve the right to provide our services to your competitors and make no promise of exclusivity in any particular market segment. You further acknowledge and agree that WA Easy API employees and contractors may also be WA Easy API customers/merchants and that they may compete with you, although they may not use your Confidential Information in doing so.</li>
            <li>In the event of a dispute regarding Account ownership, we reserve the right to request documentation to determine or confirm Account ownership. Documentation may include, but is not limited to, a scanned copy of your business license, government issued photo ID, the last four digits of the credit card on file, your status as an employee of an entity, etc.</li>
            <li>WA Easy API retains the right to determine, in our sole judgment, rightful Account ownership and transfer an Account to the rightful Store Owner. If we are unable to reasonably determine the rightful Store Owner, without prejudice to our other rights and remedies, WA Easy API reserves the right to temporarily disable an Account until resolution has been determined between the disputing parties.</li>
            <li>WA Easy API reserves the right, in its sole judgment/wisdom, to suspend its Services with a prior notice of thirty (30) days to the businesses. It is expected from the businesses to retrieve only the relevant data (customer information, transaction history, etc) from the dashboard within these  30 days. WA Easy API will not be liable to share any data post expiration of these 30 days and under no circumstances source code will be shared with any party.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="activities">
          <h3 class="mb-5">{{ pitems[3] }}</h3>
          <ol>
            <li>The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by the Company.</li>
            <li>You agree not to systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
            <li>You agree not to make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
            <li>You agree not to circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.</li>
            <li>You agree not to engage in unauthorized framing of or linking to the Site.</li>
            <li>You agree not to trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
            <li>You agree not to make improper use of our support services or submit false reports of abuse or misconduct.</li>
            <li>You agree not to attempt to impersonate another user or person or use the username of another user.</li>
            <li>You agree not to except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.</li>
            <li>You agree not to disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>
            <li>You agree not to use the Site in a manner inconsistent with any applicable laws or regulations, including but not limited to Information Technology Act, 2000 and the Regulations made therein.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="payment">
          <h3 class="mb-5">{{ pitems[4] }}</h3>
          <ol>
            <li>You will pay the Fees applicable to your subscription to Online Service (“Subscription Fees”) and any other applicable fees, including but not limited to applicable fees relating to the value of sales made through your Store (“Transaction Fees”), and any fees relating to your purchase or use of any products or services such as WA Easy API Payments, domain names, or Third Party Services (“Additional Fees”). Together, the Subscription Fees, Transaction Fees and the Additional Fees are referred to as the “Fees”.</li>
            <li>The Fees to be paid by you will depend upon the plan subscribed by you from the list available in the website any other purchases made from the WA Easy API website/platform such as purchase of SMS credit, purchase of storage space, customization, etc. It is expressly agreed by you that the Fees is non-refundable.</li>
            <li>You must keep a valid payment method on file with us to pay for all incurred and recurring Fees.  WA Easy API will charge applicable Fees to any valid payment method that you authorize (“Authorized Payment Method”), and WA Easy API will continue to charge the Authorized Payment Method for applicable Fees until the Services are terminated, and any and all outstanding Fees have been paid in full. Unless otherwise indicated, all Fees and other charges are in INR , and all payments shall be in INR.</li>
            <li>Subscription Fees are paid in advance and will be billed as per the duration of the plan (each such date, a “Billing Date”). Transaction Fees and Additional Fees will be charged from time to time at WA Easy API’s discretion. You will be charged on each Billing Date for all outstanding Fees that have not previously been charged. Fees will appear on an invoice, which will be sent to the Store Owner/businesses of WA Easy API via the email provided. As well, an invoice will appear on the Account page of your Store’s administration console.</li>
            <li>Users have to raise issues with the billing of Fees within 7 days from the date of the invoice.</li>
            <li>If you do not pay the Fees as per the Billing cycle, we will suspend and revoke access to your Account and the Services. Your Account will be reactivated upon your payment of any outstanding Fees, plus the Fees applicable to your next billing cycle. You may not be able to access your Account or your storefront during any period of suspension. If the outstanding Fees remain unpaid for 60 days following the date of suspension, WA Easy API reserves the right to terminate your Account.</li>
            <li>Unless stated otherwise, all Fees are exclusive of applicable central, state, local or other governmental sales, goods and services, harmonized or other taxes, fees or charges now in force or enacted in the future (“Taxes”).</li>
            <li>You are responsible for all applicable Taxes that arise from or as a result of your subscription to or purchase of WA Easy API ’s services. To the extent that WA Easy API charges these Taxes, they are calculated using the tax rates that apply based on the billing address you provide to us. Such amounts are in addition to the Fees for such products and services and will be billed to your Authorized Payment Method. If you are exempt from payment of such Taxes, you must provide us with evidence of your exemption, which in some jurisdictions includes an original certificate that satisfies applicable legal requirements attesting to tax-exempt status. Tax exemption will only apply from and after the date we receive evidence satisfactory to WA Easy API of your exemption. If you are not charged Taxes by WA Easy API, you are responsible for determining if Taxes are payable, and if so, self-remitting Taxes to the appropriate tax authorities in your jurisdiction.</li>
            <li>For the avoidance of doubt, all sums payable by you to WA Easy API under these Terms of Service shall be paid free and clear of any deductions or withholdings whatsoever. Other than Taxes charged by WA Easy API to you and remitted to the appropriate tax authorities on your behalf, any deductions or withholdings that are required by law shall be borne by you and paid separately to the relevant taxation authority. WA Easy API shall be entitled to charge the full amount of Fees stipulated under these Terms of Service to your Authorized Payment Method ignoring any such deduction or withholding that may be required.</li>
            <li>You are responsible for all applicable Taxes that arise from or as a result of any sale on your WA Easy API Store.</li>
            <li>You must maintain an accurate location in the administration menu of your WA Easy API Store. If you change jurisdictions you must promptly update your location in the administration menu.</li>
            <li>WA Easy API does not provide refunds.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="third-party">
          <h3 class="mb-5">{{ pitems[5] }}</h3>
          <ol>
            <li>The Site may contain (or you may be sent via the Site) links to other websites ("Third-Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties ("Third-Party Content").</li>
            <li>Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the Site or any Third-Party Content posted on, available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.</li>
            <li>If you choose to access such Third-Party Websites and Third-Party Content, you do so at your own risk and the Company cannot be held liable for any act of such third parties in any manner whatsover.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="security">
          <h3 class="mb-5">{{ pitems[6] }}</h3>
          <ol>
            <li>We have implemented certains measures designed to secure your personal information from accidental destruction, loss, alteration and from unauthorized access, use, alteration, or disclosure. However, we cannot guarantee that unauthorized third parties will never be able to defeat those measures or use your personal information for improper purposes. You provide your personal information solely at your own risk.</li>
            <li>You are solely responsible for safeguarding your password and for restricting access to the Services from your compatible mobile devices and computer(s). You will immediately notify us of any unauthorized use of your password or WA Easy API Account or any other breach of security. You will immediately take all reasonable steps to mitigate the effects of a security breach and will cooperate with WA Easy API and provide all information requested by Square to remediate the breach. Any assistance provided by WA Easy API in relation to a security breach does not in any way operate as acceptance or acknowledgement that WA Easy API is in any way responsible or liable to you or any other party in connection with such breach.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="confidentiality">
          <h3 class="mb-5">{{ pitems[7] }}</h3>
          <ol>
            <li>By using the Site, you agree to be bound by our Privacy Policy, which is deemed to be a part and parcel of these T&Cs.</li>
            <li>The Company ensures the confidentiality of your data is maintained and does not engage in commercialization of Client’s data by selling it to third party.</li>
            <li>
              Upon completion of the Services or termination of these T&Cs the Company shall:
              <ol type="a">
                <li>not retain any written or tangible material (including information stored on a computer/laptop, floppies, CDs and/or any other storage device/media) containing any information concerning Client’s data;</li>
                <li>destroy your data on the 60th  day from completion of the Services or termination of WA Easy API account for reasons stated as above.</li>
              </ol>  
            </li>
            <li>The Company respects the rights you have over your data and acts in accordance to the Indian data protection and other local laws.</li>
            <li>If you access the Site from the European Union, United States, Asia, or any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in India, then through your continued use of the Site, you are transferring your data to India, and you expressly consent to have your data transferred to and processed in India in accordance to all applicable Indian laws, without regard to principles of conflicts of laws.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="property">
          <h3 class="mb-5">{{ pitems[8] }}</h3>
          <ol>
            <li>The contents of available on the Site are sole property of the Company and you by virtue of the use of the website or this T&Cs does not develop any right over the same.</li>
            <li>The Company retains full ownership of all the content available on its Site and any intellectual property rights or other proprietary rights associated with Contributions made on the site by Client. You undertake not to violate such rights.</li>
            <li>The Company may come in possession of Client’s intellectual property for the furtherance of rendering the Services. The Company shall not make use of Client’s intellectual property including but not limited to trademark, design, logo, patent for any other purpose other than rendering the Services.</li>
            <li>The Company respects the intellectual property rights of others. If you believes that any material available on or through the Site infringes upon any intellectual property rights you owns or controls, please immediately notify the Company on team@waeasyapi.com</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="liability">
          <h3 class="mb-5">{{ pitems[9] }}</h3>
          <ol>
            <li>You expressly understand and agree that, to the extent permitted by applicable laws, WA Easy API shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses resulting from the use of or inability to use the Service.</li>
            <li>To the extent permitted by applicable laws, in no event shall WA Easy API or our suppliers be liable for lost profits or any special, incidental or consequential damages arising out of or in connection with our site, our Services or these Terms of Service (however arising including negligence). You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, WA Easy API partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your breach of these Terms of Service or the documents it incorporates by reference (including the AUP), or your violation of any law or the rights of a third party.</li>
            <li>Your use of the Services is at your sole risk. The Services are provided on an “as is” and “as available” basis without any warranty or condition, express, implied or statutory.</li>
            <li>WA Easy API is not a publisher of the content supplied by third parties; as such, WA Easy API exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability or currency of any information, content, service or merchandise provided through or accessible via the WA Easy API Service.</li>
            <li>WA Easy API does not warrant that the Services will be uninterrupted, timely, secure, or error-free.</li>
            <li>WA Easy API does not warrant that the results that may be obtained from the use of the Services will be accurate or reliable.</li>
            <li>WA Easy API is not responsible for any of your tax obligations or liabilities related to the use of WA Easy API’ s Services.</li>
            <li>WA Easy API does not warrant that the quality of any products, services, information, or other materials purchased or obtained by you through the Services will meet your expectations, or that any errors in the Services will be corrected.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="waiver">
          <h3 class="mb-5">{{ pitems[10] }}</h3>
          <ol>
            <li>The failure of WA Easy API to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. If any provision of the Terms of Service, including all terms and conditions and other documents it incorporates by reference, is held by a court of competent jurisdiction to be contrary to law, such provision shall be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law, and the remaining provision of the Terms of Service shall remain in full force and effect.</li>
            <li>The Terms of Service, including the documents it incorporates by reference, constitute the entire agreement between you and WA Easy API and govern your use of the Services and your Account, superseding any prior agreements between you and WA Easy API (including, but not limited to, any prior versions of the Terms of Service).</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="termination">
          <h3 class="mb-5">{{ pitems[11] }}</h3>
          <ol>
            <li>T&Cs shall remain in full force and effect while you use and registers on the Site.</li>
            <li>The Company reserves the right to, in our sole discretion and without notice or liability, deny access to and use of the site (including blocking certain IP addresses), to any person for any reason, including without limitation for breach of any representation, warranty, or covenant contained in these T&Cs or of any applicable law or regulation.</li>
            <li>The Company may terminate Client’s use or participation in the Site or delete your account or any content or information that you posted at any time, in its sole discretion.</li>
            <li>If the Company terminates or suspends your account for any reason, Client is prohibited from registering and creating a new account under its own name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="dispute">
          <h3 class="mb-5">{{ pitems[12] }}</h3>
          <ol>
            <li>All disputes arising between the partners as to the interpretation, operation, or effect of any clause of these T&Cs shall first be attempted to be resolved by informal negotiations.</li>
            <li>If the Parties are unable to resolve a dispute through informal negotiation, the Dispute will be first referred to Mediation as per the consent of both the parties.</li>
            <li>If the dispute still remains unresolved, then the same will be  resolved by binding arbitration.</li>
            <li>The arbitration proceedings shall be commenced and conducted under the Arbitration and Conciliation Act, 1996 and the Rules made therein.</li>
            <li>The venue and seat of arbitration for the purpose would be Jaipur.</li>
            <li>That the courts of Jaipur or any other courts as may be mutually decided shall have jurisdiction in case of any dispute arising out of this T&C.</li>
          </ol>
        </div>

        <div class="mt-12"></div>
        <div id="limitation">
          <h3 class="mb-5">{{ pitems[13] }}</h3>
          <p>Any action or proceeding by you relating to any Dispute must commence within six month after the cause of action accrues.</p>
        </div>

        <div class="mt-12"></div>
        <div id="hyperlink">
          <h3 class="mb-5">{{ pitems[14] }}</h3>
          <p>The following organizations may link to our Website without prior written approval:</p>
          <ul>
            <li>Government agencies;</li>
            <li>Search engines;</li>
            <li>News organizations;</li>
            <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
            <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
          </ul>
          
          <p></p>
          <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>
          <p>We may consider and approve other link requests from the following types of organizations:</p>
          <ul>
            <li>commonly-known consumer and/or business information sources;</li>
            <li>dot.com community sites;</li>
            <li>associations or other groups representing charities;</li>
            <li>online directory distributors;</li>
            <li>internet portals;</li>
            <li>accounting, law and consulting firms; and</li>
            <li>educational institutions and trade associations.</li>
          </ul>

          <p></p>
          <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of WA Easy API; and (d) the link is in the context of general resource information.</p>
          <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>
          <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to WA Easy API. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
          <p>Approved organizations may hyperlink to our Website as follows:</p>
          <ul>
            <li>By use of our corporate name; or</li>
            <li>By use of the uniform resource locator being linked to; or</li>
            <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
          </ul>

          <p></p>
          <p>No use of WA Easy API's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
        </div>

        <div class="mt-12"></div>
        <div id="iframe">
          <h3 class="mb-5">{{ pitems[15] }}</h3>
          <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
        </div>

        <div class="mt-12"></div>
        <div id="removal">
          <h3 class="mb-5">{{ pitems[16] }}</h3>
          <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
          <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
        </div>

        <div class="mt-12"></div>
        <div id="contact">
          <h3 class="mb-5">{{ pitems[17] }}</h3>
          <p>If you have any questions or suggestions about our Terms & Conditions, do not hesitate to contact us at team@waeasyapi.com.</p>
        </div>

      </v-col>
    </v-row>
      

    <div style="height: 100px;"></div>
  </v-container>
</template>
<script>
import $ from 'jquery';

export default {
  name: 'TermsAndConditions',

  created() {
    document.title = 'WA Easy API - Terms and Conditions';
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  methods: {
    goto(index) {
      let elementID = 'general';
      if (index == 1) elementID = 'account';
      else if (index == 2) elementID = 'rights';
      else if (index == 3) elementID = 'activities';
      else if (index == 4) elementID = 'payment';
      else if (index == 5) elementID = 'third-party';
      else if (index == 6) elementID = 'security';
      else if (index == 7) elementID = 'confidentiality';
      else if (index == 8) elementID = 'property';
      else if (index == 9) elementID = 'liability';
      else if (index == 10) elementID = 'waiver';
      else if (index == 11) elementID = 'termination';
      else if (index == 12) elementID = 'dispute';
      else if (index == 13) elementID = 'limitation';
      else if (index == 14) elementID = 'hyperlink';
      else if (index == 15) elementID = 'iframe';
      else if (index == 16) elementID = 'removal';
      else if (index == 17) elementID = 'contact';

      $('html, body').animate({ scrollTop: $('#'+elementID).offset().top }, 'slow');
      this.activeIndex = index;
    }
  },

  data() {
    return {
      activeIndex: 0,
      pitems: [
        'General conditions',
        'Account terms and activation',
        'WA Easy API rights',
        'Prohibited activities',
        'Payment of fees',
        'Third party websites and content',
        'Security',
        'Confidentiality and privacy policy',
        'Intellectual property',
        'Limitation of liability',
        'Waiver, severability, and complete agreement',
        'Term and termination',
        'Dispute resolution',
        'Limitation on time to initiate a dispute',
        'Hyperlinking to our content',
        'iFrames',
        'Removal of links from our website',
        'Contact us'
      ]
    }
  }
}
</script>