import $ from 'jquery';
import config from '../../config';

export default {
  name: 'Home',

  metaInfo() {
    return { 
      title: this.meta.title,
      meta: [
        { name: 'description', content: this.meta.description },
        { property: 'og:title', content: this.meta.title },
        { property: 'og:description', content: this.meta.description },
      ]
    }
  },

  created() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  },

  methods: {
    navigate(name) {
      return this.$router.push({ name: name }).catch(() => {});
    },

    explore() {
      $('html, body').animate({ scrollTop: $(".section-1").offset().top }, 'slow');
    },

    countryText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    signup() {
      if (!this.$refs.form.validate()) return;
      const mobile = this.$store.state.mobile;
      const callingCode = this.$store.state.callingCode;

      if (!mobile) return this.$refs.form.validate();
      window.location = `${config.signupURL}?cc=${callingCode}&mobile=${mobile}`;
    },

    copy(text) {
      navigator.clipboard.writeText(text);
    },

    copyCode() {
      const nodecode = document.getElementById('nodecode').textContent;
      this.copy(nodecode);
    }
  },

  

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    countries() {
      return this.$store.state.countries;
    }
  },

  data() {
    return {
      logo: require('@/assets/imgs/logo.png'),
      banner: require('@/assets/imgs/waea1.png'),

      banner1: require('@/assets/imgs/waea-1.png'),
      banner2: require('@/assets/imgs/waea-2.png'),
      banner3: require('@/assets/imgs/waea-3.png'),
      banner4: require('@/assets/imgs/waea-4.png'),

      meta: {
        title: 'WhatsApp Business API, Whatsapp Business API India | WA Easy API',
        description: 'WhatsApp Easy API offers official WhatsApp APIs to send and receive messages using a programming language or its UI interface.'
      },

      nodejs: 'npm i waeasyapi',
      python: 'pip install waeasyapi',
      php: 'composer require waeasyapi/waeasyapi',
      go: 'go get github.com/waeasyapi/waeasyapi-go',

      validationRules: {
        mobileRule: [
          v => (v && !isNaN(v)) || 'Enter a valid number'
        ],
      }
    }
  }
}