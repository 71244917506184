<template>
  <section class="bbg pb-15 pt-10 gestarted">
    <v-container class="pb-10 pt-10">
      <v-container>
        <v-row :class="isMobile ? '' : 'mb-15'">
          <v-col class="text-center mb-15">
            <v-img :src="banner"></v-img>  
          </v-col>
        </v-row>

        <v-row class="contact-section">
          <v-col cols="12" sm="4">
            <h1 class="mb-3">Ready to get started?</h1>
            <p class="ccsubheading mb-5">Explore WA Easy API, or create an account instantly and start sending messages. In case you're have an issue, feel free to reach us.</p>
            <v-btn small outlined rounded class="nott" @click="open('signup')"><b>Get started</b> <v-icon>mdi-chevron-right</v-icon></v-btn>
          </v-col>

          <v-col v-if="!isMobile" cols="1"></v-col>

          <v-col cols="12" sm="4" :class="[isMobile ? 'mt-10' : '']">
            <v-btn small fab outlined color="primary"><v-icon color="primary">mdi-currency-usd</v-icon></v-btn>
            <h3 class="mt-3 mb-2">Pricing</h3>
            <p class="ccsubheading mb-5">Pick a plan as per your convenience and get started; no hidden cost.</p>
            <v-btn small outlined rounded class="nott" @click="navigate('pricing')"><b>Pricing details</b> <v-icon>mdi-chevron-right</v-icon></v-btn>
          </v-col>

          <v-col cols="12" sm="3" :class="[isMobile ? 'mt-10' : 'vc pl-0', '', 'pr-0']" :style="!isMobile ? 'margin-left: -10px' : ''">
            <v-btn small fab outlined color="primary"><v-icon color="primary">mdi-email-outline</v-icon></v-btn>
            <h3 class="mt-3 mb-2">Contact</h3>
            <p class="ccsubheading mb-5">In case you're facing any issue, feel free to reach our team.</p>
            <div class="mt-3"><v-icon color="primary" left small>mdi-email</v-icon> team@waeasyapi.com</div>
            <div class="mt-3">
              <v-row>
                <v-col cols="1">
                  <v-icon color="primary" left small>mdi-map-marker</v-icon> 
                </v-col>
                <v-col>
                  <!-- <div class="mb-1"><span>WA Easy API, Koramangala,</span></div> -->
                  <span>Bengaluru, India - 560034</span>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </section>
</template>
<script>
import $ from 'jquery';
import config from '../../config';

export default {
  name: 'GetStarted',

  props: [
    'title',
  ],

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  methods: {
    navigate(name) {
      this.$router.push({ name: name }).catch(() => {});
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    },

    open(type) {
      let url = '';
      if (type == 'signin') url = config.signinURL;
      else if (type == 'signup') url = config.signupURL; 
      window.open(url, '_blank');
      this.menu = false;
    },
  },

  data() {
    return {
      banner: require('@/assets/imgs/business.png'),
    }
  }  
}
</script>