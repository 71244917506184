import $ from 'jquery';
import config from '../../config';

export default {
  name: 'Header',

  methods: {
    navigate(name) {
      if (name == 'contact') {
        $('html, body').animate({ scrollTop: $(".contact-section").offset().top }, 'slow');
      }
      
      else if (name == 'devs') {
        if (this.$route.name !== 'home') this.$router.push({ name: 'home' }).catch(() => {});
        setTimeout(() => $('html, body').animate({ scrollTop: $(".devs").offset().top }, 'slow'), 0);
      } 

      else if (name == 'api-docs') {
        window.open('https://github.com/waeasyapi/waeasyapi-node/#readme', '_blank');
      }
      
      else {
        this.$router.push({ name: name }).catch(() => {});
        $('html, body').animate({ scrollTop: 0 }, 'slow');
      }
    },

    open(type) {
      let url = '';
      if (type == 'signin') url = config.signinURL;
      else if (type == 'signup') url = config.signupURL; 

      window.open(url, '_blank');
      this.menu = false;
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    innerWidth() {
      return window.innerWidth;
    }
  },

  data() {
    return {
      items: [
        {
          _id: 1,
          href: 'website',
          title: 'Website',
        },
        {
          _id: 1,
          href: 'pos',
          title: 'POS System',
        },
        {
          _id: 3,
          href: 'app',
          title: 'Android & iOS App'
        },
        {
          _id: 4,
          href: 'dashboard',
          title: 'Sales & Inventory Dashboard'
        }
      ],

      ritems: [
        {
          _id: 1,
          href: 'blog',
          title: 'Blog',
        },
        {
          _id: 2,
          href: 'resources',
          title: 'FAQ & Videos',
        }
      ],

      menu: false,
      menuModal: false,

      logo: require('@/assets/imgs/logo.png'),
    }
  }
};