import $ from 'jquery';

export default {
  name: 'Pricing',

  metaInfo() {
    return { 
      title: this.meta.title,
      meta: [
        { name: 'description', content: this.meta.description },
        { property: 'og:title', content: this.meta.title },
        { property: 'og:description', content: this.meta.description },
      ]
    }
  },

  created() {
    if (this.$store.state.pricing) this.pricing = this.$store.state.pricing;
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  },

  computed: {
    pfetching() {
      return this.$store.state.pfetching;
    },

    currencyCode() {
      return this.currency ? 'USD' : 'INR';
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    darkDullColor() {
      return this.isDT ? 'grey darken-3' : 'grey lighten-3'
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    plans() {
      return this.$store.state.pricing.plans;
    }
  },

  methods: {
    items(plan) {
      if (plan == 'basic') return this.bitems;
      if (plan == 'premium') return this.pitems;
    },

    replacedFee(string) {
      return string.replace('{{fee}}', this.pricing.payg[this.currencyCode].fee);
    },

    navigate(name) {
      return this.$router.push({ name: name }).catch(() => {});
    },
  },

  watch: {
    '$store.state.pricing'(n) {
      if (n) this.pricing = n;
    },

    mode(n, o) {
      if (!n && n != 0) this.mode = o;
    }
  },

  data() {
    return {
      mode: 0,
      currency: 0,

      bitems: [
        'No hidden fee',
        'Easy onboarding',
        'Working hours support',
        'WhatsApp Business APIs',
        'Upto 100 message templates',
        'Free 1000 conversations / month'
      ],

      pitems: [
        'Everythin in basic plan',
        'WA Easy API Inbox',
        'Keep your messaging history',
        'Unlimited message templates',
        'Send bulk messages using broadcast',
        'Send WhatsApp messaging campaigns',
      ],

      logo: require('@/assets/imgs/logo.png'),

      meta: {
        title: 'WA Easy API - Pricing',
        description: 'Our eCommerce website development cost ranges from basic to premium and enterprise subscription based on your business needs or you can pay as you grow!'
      },
    }
  }
}