import config from '../../config';

export default {
  name: 'Footer',

  methods: {
    goto(url) {
      window.open(url, '_blank');
    },

    openURL(url) {
      window.open(url, '_blank');
    },

    social(type) {
      let url = '';
      if (type == 'facebook') url = config.facebookURL;
      if (type == 'twitter') url = config.twitterURL;
      if (type == 'telegram') url = config.telegramURL;

      window.open(url, '_blank')
    },

    navigate(name) {
      if (name == 'careers') return this.openURL(config.careerURL)
      return this.$router.push({ name: name }).catch(() => {});
    },

    openPolicyModal(type) {
      this.policyType = type;
      this.policyModal = true;
    }
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    year() {
      return new Date().getFullYear();
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      web: config.webURL,

      policyType: '',
      policyModal: false
    }
  }
}