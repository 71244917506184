import Misc from './services/misc';

import Header from './components/header/header.vue';
import Footer from './components/footer/footer.vue';
import GetStarted from './components/get-started/get-started.vue';

import Home from './pages/home/home.vue';
import Pricing from './pages/pricing/pricing.vue';
import Resources from './pages/resources/resources.vue';

import PrivacyPolicy from './pages/privacy-policy/privacy-policy.vue';
import TermsAndConditions from './pages/terms-and-conditions/terms-and-conditions.vue';

import Dummy from './pages/dummy/dummy.vue';

export default {
  name: 'App',

  components: {
    Home,
    Header,
    Footer,
    Pricing,
    Resources,
    GetStarted,
    PrivacyPolicy,
    TermsAndConditions,

    Dummy
  },

  computed: {
    routeName() {
      return this.$route.name;
    }
  },

  mounted() {
    //this.setupTheme();
    this.fetchPricing();
    this.fetchCountries();
  },

  methods: {
    fetchPricing() {
      this.$store.state.pfetching = true;
      return Misc.getPricing()
        .then(x => {
          this.$store.state.pricing = x.data;
          this.$store.state.pfetching = false;
        })
        .catch(e => {
          console.log(e);
          this.$store.state.pfetching = false;
        })
    },

    fetchCountries() {
      const countries = this.$store.state.countries;
      if (countries && countries.length) return;

      this.$store.state.cloading = true;
      return Misc.getCountries()
        .then(x => {
          this.$store.state.countries = x.data;
          this.$store.state.cloading = false;
        })
        .catch(() => this.$store.state.cloading = false)
    }
  },

  watch: {
    '$vuetify.theme.dark'(n) {
      let color = 'auto';
      let bgcolor = 'white';
      if (n) { color = '#121212 #1e1e1e'; bgcolor = '#1e1e1e' }
      document.getElementById('mhtml').style['scrollbar-color'] = color;
      document.getElementById('mhtml').style['background-color'] = bgcolor;

      localStorage.setItem('dark', n);
    }
  },

  data() {
    return {
      
    }
  }
};