<template>
  <section :class="[isMobile ? 'pt-5 pb-5' : 'pt-10 pb-10']">
    <v-container>
      <v-row class="mt-2">
        <v-col class="text-center mt-15">
          <img :src="logo" :width="isMobile ? 125 : 215">

          <!-- <h3>Stop all jargon!</h3>
          <h1>Let's get started</h1> -->
          <h1 :class="['mb-4', isMobile ? 'ccheadline' : 'cheadline']"><b>WhatsApp Easy API</b></h1>
          <p :class="['mb-5 mt-8', isMobile ? 'f125' : 'csubheading']">
            WhatsApp Official Business API Provider
          </p>

          <p>For eary access, write us at <u>team at waeasyapi dot com</u>.</p>

          <p>Send messages using APIs within 5 minutes</p>
          <small>Once approved by Meta :)</small>
            
          <!-- <Signup class="mt-12" :hide-details="true"></Signup> -->
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: 'Dummy',

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      logo: require('@/assets/imgs/logo.png'),
    }
  }
}
</script>

