import Vue from 'vue'
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Home from '../pages/home/home';
import Pricing from '../pages/pricing/pricing';

import PrivacyPolicy from '../pages/privacy-policy/privacy-policy.vue';
import TermsAndConditions from '../pages/terms-and-conditions/terms-and-conditions.vue';

const routes = [
  {
    path: '',
    name: 'home',
    component: Home,
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing,
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: TermsAndConditions
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  }
  //{ path: '*', component: PathNotFound }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: 'history'
});

export default router;