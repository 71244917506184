import Vue from 'vue';
import config from '../config';

export default {
  getCountries() {
    return Vue.axios.get(`country/list/active`);
  },

  getPricing() {
    return Vue.axios.get(`pricing/plans`);
  },

  saveLead(lead) {
    Vue.axios.defaults.headers.common['X-Request-Browser'] = config.websiteSecret;
    return Vue.axios.post(`lead/website`, lead);
  }
}