import $ from 'jquery';

export default {
  name: 'Resources',

  metaInfo() {
    return { 
      title: this.meta.title,
      meta: [
        { name: 'description', content: this.meta.description },
        { property: 'og:title', content: this.meta.title },
        { property: 'og:description', content: this.meta.description },
      ]
    }
  },

  created() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  },

  computed: {
    rfetching() {
      return this.$store.state.rfetching;
    },

    resources() {
      return this.$store.state.resources;
    },

    allfaqs() {
      return this.cfaqs.concat(this.faqs);
    },

    faqs() {
      return this.resources ? this.$store.state.resources.faqs : [];
    },

    videos() {
      return this.resources ? this.$store.state.resources.videos : [];
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    darkDullColor() {
      return this.isDT ? 'grey darken-3' : 'grey lighten-3'
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      cfaqs: [
        {
          title: 'What is Growcify?',
          description: 'Growcify® is an eCommerce SaaS platform helping businesses go online with their own eCommerce app, website and POS with zero coding knowledge and within 24 hours.'
        },
        {
          title: 'Can I cancel at any time?',
          description: 'Yes, you can.'
        },
        {
          title: 'If I decide to terminate, will I get refund?',
          description: 'No. We have zero refund policy.'
        },
        {
          title: 'Do I need to sign any contract or NDA?',
          description: 'No. Growcify, being a SaaS platform, does not require you to sign any contract. Simply sign up and get started.'
        },
        {
          title: 'Is my data secured?',
          description: 'Yes, your data is fully secured and no one has access to it except you.',
        },
        {
          title: 'Can I upgrade or downgrade my plan?',
          description: 'Yes, you can upgrade or downgrade your plan except if you\'ve purchased onetime plan.'
        },
        {
          title: 'If there any platform fee or commission?',
          description: 'No, the platform fee is only applicable in the \'Pay as you Grow\' plan.'
        },
        {
          title: 'Will I get source code?',
          description: 'No. Being a SaaS platform, we do not provide source code.'
        },
        {
          title: 'Is there any annual maintance charge applicable?',
          description: 'AMC is only applicable in these cases: one-time purchase or pay as you grow.'
        },
        {
          title: 'Will I get white-labbeled app?',
          description: 'Yes, your apps are fully white-labelled depending on plan features.'
        },
        {
          title: 'How many times I can request to change the logo?',
          description: '3 times. After that, it\'s paid for every request in the logo change.'
        },
        {
          title: 'Will I get domain from Growcify?',
          description: 'No. You will need to purchase a domain name registrar.'
        }
      ],

      meta: {
        title: 'Growcify - Resources',
        description: 'Have queries about Growcify? Check this page to find the most frequently asked questions of Growcify to resolve your doubts. Have more? schedule a call.'
      }
    }
  }
}